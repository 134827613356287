<template>
  <button
    v-close-popper="closeOnClick"
    :tabindex="dropdownOpened ? groupTabindex : 0"
    :disabled="disabled"
    :class="{ active, disabled }"
    class="menu-item"
    data-qa="menu-item"
  >
    <div v-if="!hideIcon" class="menu-item-icon">
      <slot name="icon">
        <SvgIcon v-if="renderIcon" :name="renderIcon" :size="20" />
      </slot>
    </div>
    <div class="menu-label">
      <slot />
    </div>
  </button>
</template>

<script setup lang="ts">
import { computed, inject, type Ref, ref } from 'vue'

import { GROUP_TAB_INDEX } from '@/components/common/dropdown/dropdownSettings'
import SvgIcon from '@/components/common/SvgIcon.vue'

const props = defineProps({
  icon: { type: String },
  closeOnClick: { type: Boolean, default: true },
  active: { type: Boolean, default: false },
  checked: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  hideIcon: { type: Boolean, default: false },
})

const dropdownOpened = inject<Ref<boolean>>('dropdownOpened', () => ref(false), true)

const groupTabindex = GROUP_TAB_INDEX

const renderIcon = computed(() => {
  if (props.checked) {
    return 'checkmark'
  }
  return props.icon
})
</script>

<style scoped lang="postcss">
.menu-item {
  @apply w-full rounded-lg py-3 px-4 bg-gray-100 flex items-center transition-colors focus:outline-none;

  &:not(.disabled):not(.active) {
    @apply hover:bg-gray-200 focus:bg-gray-200;
  }

  &.active {
    @apply bg-gray-900 text-white;
  }

  &.disabled {
    @apply text-gray-600 cursor-not-allowed;
  }
}

.menu-item-icon {
  @apply w-5 h-5 mr-2 flex-shrink-0;

  .icon {
    @apply mt-[-2px];
  }
}

.menu-label {
  @apply flex-grow text-left max-w-full overflow-hidden overflow-ellipsis flex items-center;
}
</style>
