/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GenerateSignedUrlRequest } from '../models/GenerateSignedUrlRequest';
import type { SignedUrl } from '../models/SignedUrl';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ImagesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns SignedUrl
     * @throws ApiError
     */
    public imagesSignedUrlCreate({
        requestBody,
    }: {
        requestBody: GenerateSignedUrlRequest,
    }): CancelablePromise<SignedUrl> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/images/signed-url/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
