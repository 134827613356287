<template>
  <Dropdown content-classes="min-w-[108px]">
    <template #default="{ toggleTabindex }">
      <Button
        type="secondary"
        :tabindex="toggleTabindex"
        data-qa="change-lang-button"
        v-bind="$attrs"
        icon-only
        icon="globe"
      />
    </template>
    <template #dropdown>
      <DropdownMenuItem
        v-for="locale of localesOptions"
        :key="locale.value"
        :active="locale.value === userLanguage"
        :data-qa="locale.value"
        hide-icon
        class="mb-[2px]"
        @click="changeLanguage(locale.value)"
      >
        {{ locale.text }}
      </DropdownMenuItem>
    </template>
  </Dropdown>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import Button from '@/components/common/buttons/Button.vue'
import Dropdown from '@/components/common/dropdown/Dropdown.vue'
import DropdownMenuItem from '@/components/common/dropdown/DropdownMenuItem.vue'
import dayjs from '@/dayjs'
import { LanguageEnum } from '@/generated/api'
import { useAuthStore } from '@/modules/auth/store'

const store = useAuthStore()
const i18n = useI18n()
const { t, availableLocales } = useI18n()

const userLanguage = computed(() => store.user?.language)

const localesOptions = computed(() =>
  availableLocales.map((value: string) => ({
    text: t(`common.settings.${value}`),
    value,
  }))
)

async function changeLanguage(lang: string) {
  if (lang === userLanguage.value) return
  try {
    if (store.user?.id) {
      await store.updateFanvestorSystemSettings({ language: lang as LanguageEnum })
    }
    i18n.locale.value = lang
    dayjs.locale(i18n.locale.value)
    localStorage.setItem('lang', i18n.locale.value)
  } catch (e) {
    console.error(e)
  }
}
</script>
