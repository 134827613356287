import type { RouteRecordRaw } from 'vue-router'

import LayoutWithoutLogoAndHighlightBG from '@/layouts/LayoutWithoutLogoAndHighlightBG.vue'

import AuthLayout from './layouts/AuthLayout.vue'

export const LOGIN = 'auth.login'
export const FANVESTOR_PROFILE = 'auth.fan-profile'
export const CLUB_PROFILE = 'auth.club-profile'
export const CONFIRMATION = 'auth.confirmation'
export const EMAIL_VERIFICATION = 'auth.email-verification'
export const PASSWORD_RESET = 'auth.password-reset'

export const VERIFICATION_INFO = 'auth.verification-info'

export default [
  {
    path: '/:sport/auth',
    redirect: { name: LOGIN },
    children: [
      {
        path: 'login',
        name: LOGIN,
        component: () => import('./views/LoginView.vue'),
      },
      {
        path: 'profile-fan',
        name: FANVESTOR_PROFILE,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
          auth: true,
        },
        component: () => import('./views/FanvestorProfile.vue'),
      },
      {
        path: 'profile-club',
        name: CLUB_PROFILE,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
          auth: true,
        },
        component: () => import('./views/ClubUserProfile.vue'),
      },
      {
        path: 'confirmation',
        name: CONFIRMATION,
        component: () => import('./views/ConfirmationView.vue'),
      },
      {
        path: 'email-verification',
        name: EMAIL_VERIFICATION,
        component: () => import('./views/LoginView.vue'),
      },
      {
        path: 'profile/verification-info',
        name: VERIFICATION_INFO,
        meta: {
          layout: LayoutWithoutLogoAndHighlightBG,
          auth: true,
        },
        component: () => import('./views/VerificationInfo.vue'),
      },
      {
        path: 'password-reset',
        name: PASSWORD_RESET,
        component: () => import('./views/PasswordReset.vue'),
      },
    ],
    meta: {
      auth: false,
      layout: AuthLayout,
    },
  },
] as RouteRecordRaw[]
