<template>
  <div class="overlay">
    <slot />
  </div>
</template>

<style scoped>
.overlay {
  @apply fixed inset-0 z-[970] flex flex-col items-center justify-center bg-black opacity-50;
}
</style>
