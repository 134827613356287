// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Callback = (...args: any[]) => void

export default class EventBus {
  private events: Record<string, Callback[]> = {}

  on(event: string, callback: Callback) {
    if (!this.events[event]) {
      this.events[event] = []
    }
    this.events[event].push(callback)
  }

  once(event: string, eventCallback: Callback) {
    const listener = (...args: unknown[]) => {
      this.off(event, listener)
      eventCallback(...args)
    }

    return this.on(event, listener)
  }

  off(event: string, callback?: Callback) {
    if (callback) {
      this.events[event] = this.events[event].filter((cb) => cb !== callback)
    } else {
      this.events[event] = []
    }
  }

  emit(event: string, ...args: unknown[]) {
    if (this.events[event]) {
      this.events[event].forEach((eventCallback) => eventCallback(...args))
    }
  }

  getEvents() {
    return this.events
  }
}
