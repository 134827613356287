import { defineEnv } from 'static-build-env'
const env = defineEnv() as Record<string, unknown>

const envNumber = (value: string | undefined | null, defaultValue: number) => {
  const number = Number(value)
  return Number.isNaN(number) ? defaultValue : number
}

export const ENV = env.ENV || import.meta.env.ENV || 'local'
export const SENTRY_DSN = env.SENTRY_DSN || import.meta.env.SENTRY_DSN || ''
export const SENTRY_TRACE_SAMPLE_RATE = envNumber(
  env.SENTRY_TRACE_SAMPLE_RATE || import.meta.env.SENTRY_TRACE_SAMPLE_RATE,
  0.1
)

export const GTM_ID = env.GTM_ID || import.meta.env.GTM_ID || ''

export const RELEASE_ID = env.RELEASE_ID || ''
export const CI_BUILD_DATE = env.CI_BUILD_DATE || ''
