<script setup lang="ts">
import { Capacitor } from '@capacitor/core'
import { onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'

import { LOGIN } from '@/modules/auth/routes'
import { useAuthStore } from '@/modules/auth/store'
import { UserRole } from '@/modules/auth/types'
import { CLUB } from '@/modules/club/routes'
import { FANVESTOR } from '@/modules/fanvestor/routes'
import { SPORTS } from '@/utils/variables'

const authSrote = useAuthStore()
const router = useRouter()

onBeforeMount(() => {
  if (Capacitor.isNativePlatform() && !authSrote.user) {
    router.push({ name: LOGIN })
  } else {
    router.push(
      authSrote.user && authSrote.user.role === UserRole.ClubUser
        ? { name: CLUB, params: { clubId: authSrote.user.club_id, sport: authSrote.user?.sport } }
        : { name: FANVESTOR, params: { sport: SPORTS.FOOTBALL } }
    )
  }
})
</script>
