<template>
  <ModalPopup :open="open">
    <template #content>
      <div class="content">
        <div class="title">{{ $t('auth.terms-and-conditions-modal.title') }}</div>
        <div class="text">
          <span>{{ $t('auth.terms-and-conditions-modal.text') }}</span>
          <a class="link" target="_blank" download="terms_of_use.pdf" :href="termsOfUseDocumentUrl"
            >{{ ` ${$t('auth.terms-and-conditions-modal.terms-and-conditions')}` }}
          </a>
        </div>
      </div>
    </template>
    <template #actions>
      <div class="flex">
        <ModalPopupButton @click="onAcceptClicked" class="grow"> Accept </ModalPopupButton>
        <ModalPopupButton @click="onDeclineClicked" class="grow"> Decline </ModalPopupButton>
      </div>
    </template>
  </ModalPopup>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import ModalPopupButton from '@/components/common/buttons/ModalPopupButton.vue'
import ModalPopup from '@/components/common/modal-popup/ModalPopup.vue'
import { LOGIN } from '@/modules/auth/routes'
import { useAuthStore } from '@/modules/auth/store'

const props = defineProps<{
  open?: boolean
}>()

const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
}>()

const authStore = useAuthStore()
const router = useRouter()
const termsOfUseDocumentUrl = ref()

watch(
  () => props.open,
  async (value) => {
    if (value) {
      termsOfUseDocumentUrl.value = (await authStore.getTermsOfUseDocumentUrl()).document
    }
  }
)

const onAcceptClicked = async () => {
  await authStore.acceptTermsOfUse()
  emit('update:open', false)
}

const onDeclineClicked = () => {
  authStore.logout()
  router.push({ name: LOGIN })
  emit('update:open', false)
}
</script>

<style scoped lang="postcss">
.content {
  @apply p-8 flex flex-col gap-5;
}

.title {
  @apply text-2xl font-extrabold italic text-accent-500;
}

.text {
  @apply text-base font-regular text-gray-600;
}

.link {
  @apply text-accent-500 hover:text-accent-700;
}
</style>
