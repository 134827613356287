import { inject } from 'vue'

import type { ToastService } from '@/components/common/toast/ToastService'

export const ToastSymbol = Symbol()

export function useToast(): ToastService {
  const Toast = inject(ToastSymbol)

  if (!Toast) {
    throw new Error('No Toast provided!')
  }

  return <ToastService>Toast
}
