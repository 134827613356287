<template>
  <div class="flex gap-2 items-center">
    <div @click="switchToFootball">
      <LogoSvgCT class="logo" v-if="route.params.sport === SPORTS.FOOTBALL" />
      <LogoSvgCTInvactive class="logo" v-else />
    </div>
    <SvgIcon class="text-accent-500" name="separator-2" />
    <div @click="switchToHockey">
      <LogoSvgCH class="logo" v-if="route.params.sport === SPORTS.HOCKEY" />
      <LogoSvgCHInvactive class="logo" v-else />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'

import LogoSvgCH from '@/assets/images/logos/logo-ch.svg?component'
import LogoSvgCHInvactive from '@/assets/images/logos/logo-ch-inactive-dark.svg?component'
import LogoSvgCT from '@/assets/images/logos/logo-ct.svg?component'
import LogoSvgCTInvactive from '@/assets/images/logos/logo-ct-inactive-dark.svg?component'
import SvgIcon from '@/components/common/SvgIcon.vue'
import { SPORTS } from '@/utils/variables'

const route = useRoute()
const router = useRouter()

const switchToFootball = () => {
  router.push(router.currentRoute.value.path.replace(SPORTS.HOCKEY, SPORTS.FOOTBALL))
}
const switchToHockey = () => {
  router.push(router.currentRoute.value.path.replace(SPORTS.FOOTBALL, SPORTS.HOCKEY))
}
</script>
<style scoped lang="postcss">
.logo {
  @apply h-5 w-[134px] cursor-pointer;
}
</style>
