/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';
import { ClubsService } from './services/ClubsService';
import { CountriesService } from './services/CountriesService';
import { ImagesService } from './services/ImagesService';
import { NotificationsService } from './services/NotificationsService';
import { OgWalletService } from './services/OgWalletService';
import { TokenService } from './services/TokenService';
import { TransfersService } from './services/TransfersService';
import { UsersService } from './services/UsersService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class ApiClient {
    public readonly clubs: ClubsService;
    public readonly countries: CountriesService;
    public readonly images: ImagesService;
    public readonly notifications: NotificationsService;
    public readonly ogWallet: OgWalletService;
    public readonly token: TokenService;
    public readonly transfers: TransfersService;
    public readonly users: UsersService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.clubs = new ClubsService(this.request);
        this.countries = new CountriesService(this.request);
        this.images = new ImagesService(this.request);
        this.notifications = new NotificationsService(this.request);
        this.ogWallet = new OgWalletService(this.request);
        this.token = new TokenService(this.request);
        this.transfers = new TransfersService(this.request);
        this.users = new UsersService(this.request);
    }
}

