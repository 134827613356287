/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationModel } from '../models/NotificationModel';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class NotificationsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns NotificationModel
     * @throws ApiError
     */
    public notificationsList(): CancelablePromise<Array<NotificationModel>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/notifications/',
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public notificationsAckCreate({
        notificationPk,
    }: {
        notificationPk: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/notifications/{notification_pk}/ack/',
            path: {
                'notification_pk': notificationPk,
            },
        });
    }
}
