<template>
  <div
    class="toast-message"
    :class="containerClass"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div class="toast-message-content">
      <Notification
        :severity="message.severity"
        :summary="message.summary"
        :detail="message.detail"
        :icon="message.icon"
      />
      <div v-if="message.closable !== false" class="close-button" @click="close">
        <div class="close-content">
          <div v-if="message.closeText" class="close-text">{{ message.closeText }}</div>
          <SvgIcon v-else name="crossmark" :size="20" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, type PropType, ref } from 'vue'

import Notification from '@/components/common/form/Notification.vue'
import SvgIcon from '@/components/common/SvgIcon.vue'
import type { Message } from '@/components/common/toast/Message'

const closeTimeout = ref<ReturnType<typeof setTimeout> | null>(null)

const props = defineProps({
  message: { type: Object as PropType<Message>, required: true },
})

const emit = defineEmits<{
  (e: 'close', message: Message): void
}>()

const containerClass = computed(() => `toast-message-${props.message.severity}`)

const clearCloseTimeout = () => {
  if (closeTimeout.value) {
    clearTimeout(closeTimeout.value)
    closeTimeout.value = null
  }
}
const close = () => {
  clearCloseTimeout()
  emit('close', props.message)
}

onMounted(() => {
  if (props.message.ttl) {
    closeTimeout.value = setTimeout(close, props.message.ttl)
  }
})

onBeforeUnmount(clearCloseTimeout)
</script>

<style scoped lang="postcss">
.toast-message {
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  @apply bg-gray-100 text-base text-gray-900 mb-1 w-[calc(100vw-40px)] sm:w-auto;
}

.toast-message :deep(.notification) {
  @apply flex-1 bg-transparent pr-5 sm:min-w-[24rem] sm:max-w-[24rem] border-0;

  .notification-icon {
    @apply text-gray-100;
  }

  .detail {
    @apply text-gray-900;
  }
}

.toast-message :deep(.notification.info .notification-icon) {
  @apply text-info-600;
}

.toast-message :deep(.notification.success .notification-icon) {
  @apply text-key-600;
}

.toast-message :deep(.notification.warn .notification-icon) {
  @apply text-warning-600;
}

.toast-message :deep(.notification.error .notification-icon) {
  @apply text-danger-600;
}

.toast-message-content {
  @apply flex items-start;
}

.close-button {
  @apply cursor-pointer self-stretch flex border-l border-gray-300 transition-colors;
}

.close-content {
  @apply p-3 self-stretch flex flex-col justify-center text-gray-500 hover:text-gray-600 hover:bg-gray-200 transition-colors rounded-r-[10px];
}

.close-text {
  @apply px-2 whitespace-nowrap font-bold;
}
</style>
