import './assets/main.css'

import { createGtm } from '@gtm-support/vue-gtm'
import { QuillEditor } from '@vueup/vue-quill'
import FloatingVue from 'floating-vue'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import { Tab, Tabs } from 'vue3-tabs-component'

import ToastPlugin from '@/components/common/toast/ToastService'
import i18n from '@/i18n'
import { GTM_ID } from '@/settings'

import App from './App.vue'
import router from './router'
import { initSentry } from './sentry'

const app = createApp(App).use(createPinia())

initSentry(app, router)

app.use(router)

if (GTM_ID)
  app.use(
    createGtm({
      id: GTM_ID,
      vueRouter: router,
    })
  )

app.use(i18n)
app.use(ToastPlugin)
app.use(VueDOMPurifyHTML)
app.use(FloatingVue, {
  themes: {
    'profile-menu': {
      $extend: 'dropdown',
      position: 'bottom-end',
    },
    default: {
      $extend: 'dropdown',
      position: 'bottom-end',
    },
    'select-dropdown': {
      $extend: 'dropdown',
      position: 'bottom-end',
    },
  },
})

app.component('tabs', Tabs)
app.component('tab', Tab)
app.component('QuillEditor', QuillEditor)

app.mount('#app')
