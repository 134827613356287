import type { I18nOptions } from 'vue-i18n'
import { createI18n } from 'vue-i18n'

// import ar from '@/locale/ar.yaml'
import de from '@/locale/de.yaml'
import en from '@/locale/en.yaml'
// import es from '@/locale/es.yaml'
import fr from '@/locale/fr.yaml'
// import it from '@/locale/it.yaml'
// import nl from '@/locale/nl.yaml'
// import pt from '@/locale/pt.yaml'

// fix for ts-issue "Type instantiation is excessively deep and possibly infinite." when using i18n.global.t
// https://github.com/intlify/vue-i18n-next/issues/1119

const messages: I18nOptions['messages'] = {
  en,
  de,
  // es,
  fr,
  // it,
  // nl,
  // pt,
  // ar,
}

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('lang') || 'de',
  fallbackLocale: 'en',
  globalInjection: true,
  messages,
})

export default i18n
