import { uniqueId } from 'lodash'
import { ref } from 'vue'

import type { Message } from '@/components/common/toast/Message'
import { ToastSymbol } from '@/composables/useToast'
import { ApiError } from '@/generated/api'
import EventBus from '@/utils/classes/EventBus'

const DEFAULT_OFFSET = 20
const DEFAULT_TIME = 4000

export class ToastService {
  private offsetValue = ref(DEFAULT_OFFSET)

  private readonly eventBusInstance!: EventBus

  constructor(eventBus: EventBus) {
    this.eventBusInstance = eventBus
  }

  add(message: Message) {
    this.eventBusInstance.emit('add', { id: uniqueId(), ...message })
  }

  error(error: string) {
    this.eventBusInstance.emit('add', {
      id: uniqueId(),
      ...{
        severity: 'error',
        detail: error,
        closable: false,
        ttl: DEFAULT_TIME,
      },
    })
  }

  success(text: string) {
    this.eventBusInstance.emit('add', {
      id: uniqueId(),
      ...{
        severity: 'success',
        detail: text,
        closable: false,
        ttl: DEFAULT_TIME,
      },
    })
  }

  info(text: string) {
    this.eventBusInstance.emit('add', {
      id: uniqueId(),
      ...{
        severity: 'info',
        detail: text,
        closable: false,
        ttl: DEFAULT_TIME,
      },
    })
  }

  warn(text: string) {
    this.eventBusInstance.emit('add', {
      id: uniqueId(),
      ...{
        severity: 'warn',
        detail: text,
        closable: false,
        ttl: DEFAULT_TIME,
      },
    })
  }

  neutral(text: string) {
    this.eventBusInstance.emit('add', {
      id: uniqueId(),
      ...{
        severity: 'neutral',
        detail: text,
        closable: false,
        ttl: DEFAULT_TIME,
      },
    })
  }

  APIError(e: any) {
    if (e instanceof ApiError) {
      if (e?.body?.detail) {
        this.eventBusInstance.emit('add', {
          id: uniqueId(),
          ...{
            severity: 'error',
            detail: e.body.detail,
            closable: false,
            ttl: DEFAULT_TIME,
          },
        })
      }
      if (e?.body?.non_field_errors?.length) {
        e.body.non_field_errors.forEach((error: string) => {
          this.eventBusInstance.emit('add', {
            id: uniqueId(),
            ...{
              severity: 'error',
              detail: error,
              closable: false,
              ttl: DEFAULT_TIME,
            },
          })
        })
      }
    }
  }

  removeGroup(group: string) {
    this.eventBusInstance.emit('remove-group', group)
  }

  removeAllGroups() {
    this.eventBusInstance.emit('remove-all-groups')
  }

  get offset(): string {
    return `${this.offsetValue.value}px`
  }

  get eventBus() {
    return this.eventBusInstance
  }

  setOffset(value?: number): void {
    this.offsetValue.value = value !== undefined ? value : DEFAULT_OFFSET
  }

  resetOffset(): void {
    this.setOffset()
  }
}

export const toastService = new ToastService(new EventBus())

export default {
  install(app: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,no-param-reassign
    app.config.globalProperties.$toast = toastService
    app.provide(ToastSymbol, toastService)
  },
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $toast: ToastService
  }
}
