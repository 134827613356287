import { BrowserTracing } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import type { App } from 'vue'
import type { Router } from 'vue-router'

import { ENV, SENTRY_DSN, SENTRY_TRACE_SAMPLE_RATE } from './settings'

export function initSentry(app: App, router: Router) {
  if (SENTRY_DSN) {
    Sentry.init({
      app,
      dsn: SENTRY_DSN,
      environment: ENV,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: ['localhost', /^\//],
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE,
      beforeSend(event) {
        // Discard "Failed to fetch dynamically imported module" event for Sentry. This is triggered by changing chunks hashes
        // for components with changed code while deploying.

        if (
          event.exception?.values?.[0]?.value?.includes(
            'Failed to fetch dynamically imported module'
          )
        ) {
          return null
        }
        return event
      },
    })
  }
}
