import type {
  RegionsEnum,
  TransferPlayerFundingTypeEnum,
  TransferTeamFundingTypeEnum,
  TransferTransferFundingTypeEnum,
} from '@/generated/api'
import { Sport } from '@/types/common'

export const POSITIONS = {
  GOALKEEPER: 'GK',
  CENTER_BACK: 'CB',
  RIGHT_BACK: 'RB',
  LEFT_BACK: 'LB',
  DEFENCIVE_MIDFIELDER: 'CDM',
  CENTRAL_MIDFIELDER: 'CM',
  ATTACKING_MIDFIELDER: 'AM',
  RIGHT_WINDER: 'RW',
  LEFT_WINGER: 'LW',
  CENTRAL_FORWARD: 'CF',
}

export const POSITION_TYPES = {
  GOALKEEPER: 'GOALKEEPER',
  DEFENSE: 'DEFENSE',
  MIDFIELD: 'MIDFIELD',
  OFFENSE: 'OFFENSE',
  ALL: 'ALL',
}

export const REGIONS: RegionsEnum[] = [
  // mocked until BE is ready
  'UEFA',
  'CAF',
  'CONCACAF',
  'CONMEBOL',
  'AFC',
  'OFC',
]

export const FUNDING_STATUSES = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  FUNDED: 'FUNDED',
  ARCHIVED: 'ARCHIVED',
}

export const FUNDING_TYPES: {
  TRANSFER: TransferTransferFundingTypeEnum
  PLAYER: TransferPlayerFundingTypeEnum
  TEAM: TransferTeamFundingTypeEnum
} = {
  PLAYER: 'PLAYER_FUNDING',
  TRANSFER: 'TRANSFER_FUNDING',
  TEAM: 'TEAM_FUNDING',
}

export const GENDERS = {
  MAN: 'MALE',
  WOMAN: 'FEMALE',
}

export const CURRENCY = {
  CHF: 'CHF',
  EUR: 'EUR',
  GBP: 'GBP',
  USD: 'USD',
}

export const CRYPTO_CURRENCY = {
  CHF: 'ZCHF',
  EUR: 'EURS',
  USD: 'USDT',
  GBP: 'GBP',
}

export const DFX_CHARGE = {
  CHF: 0.5,
  EUR: 0.6,
  USD: 0.5,
  GBP: 0.5,
}

export const MIN_INVESTMENT_AMOUNT = 25

export const MAX_IMAGE_SIZE = 5e6 // 5MB

export const UserRoles = {
  1: 'club',
  2: 'fanvestor',
}

export const CLUB_USER_POSITIONS = {
  ADMIN: 1,
  USER: 2,
}

export const REWARD_TYPES: Record<string, string> = {
  CONDITIONAL: 'CONDITIONAL',
  BOOLEAN: 'BOOLEAN',
  CHOICES: 'CONDITIONAL_CHOICES',
}

export const REWARD_CONDITIONS = {
  GT: 'GT',
  EQ: 'EQ',
  LT: 'LT',
}

export enum imagesPathsToBucket {
  funding = 'funding',
  sponsor = 'transfer-sponsors',
  profile = 'profile',
  club = 'images/club_logos',
}

export enum KYC_STATUSES {
  OPEN = 'OPEN',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  VERIFIED = 'VERIFIED',
  RESET = 'RESET',
}

export enum NOTIFICATION_TYPE {
  FAQ_POPUP = 'FAQ_POPUP',
}

export const TWO_FA_NEXT_TRY_SECONDS = 30

export const RESEND_EMAIL_VERIFICATION_ALLOWED_IN_SECONDS = 30

export enum TRANSACTION_STATUSES {
  WAITING = 'WAITING_TO_BE_PROCESSED',
  PROGRESS = 'IN_PROCESS',
  SUCCESS = 'PROCESSED_SUCCESSFULLY',
  FAILED = 'FAILED_TO_PROCESS',
  AMOUNT_MISMATCH = 'FAILED_AMOUNT_MISMATCH',
}

export const MIN_PASSWORD_LENGTH = 8

export const SUPPORT_EMAIL = 'support@crowdtransfer.io'

export const MIN_REFINANCING_AMOUNT = 10

export const SPORTS = {
  FOOTBALL: 'football',
  HOCKEY: 'hockey',
}

export const SPORT: { [key: string]: Sport } = {
  football: 'football',
  hockey: 'hockey',
}
