import { useGtm } from '@gtm-support/vue-gtm'
import dayjs from 'dayjs'
import type { RouteLocationNormalized } from 'vue-router'

import { FanVestorDetail } from '@/generated/api'
import i18n from '@/i18n'
import { UserRole } from '@/modules/auth/types'
import { FANVESTOR, FUNDING_CHECKOUT } from '@/modules/fanvestor/routes'
import { SPORTS } from '@/utils/variables'

import { CLUB_PROFILE, EMAIL_VERIFICATION, LOGIN } from './routes'
import { useAuthStore } from './store'

export const authMiddleware = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  const loginRoute = {
    name: LOGIN,
    params: { sport: from.params?.sport ? from.params.sport : SPORTS.FOOTBALL },
    query: { redirect_to: to.fullPath !== '/' ? to.fullPath : undefined },
  }

  // If the user is trying to access the 500 page, continue
  if (to.path === '/500') {
    return true
  }

  const store = useAuthStore()

  // Temporary redirect for links without sport param
  if (!to.params.sport && to.fullPath !== '/') {
    return {
      path: SPORTS.FOOTBALL + to.path,
      query: to.query,
      params: { ...to.params, sport: SPORTS.FOOTBALL },
    }
  }

  const user = await store.fetchMe()

  // If the user is trying to verify their email,
  // send a request to the server to verify it and redirect to the main page
  // or to the login page if the verification fails
  if (to.name === EMAIL_VERIFICATION) {
    return await store
      .verifyEmail(to.query.verification_code as string)
      .then(async () => {
        const user = await store.fetchMe()
        const gtm = useGtm()

        //TODO: Currently in clarification. With new registration flow there would be no birthdate and country
        gtm?.trackEvent({
          event: 'accountRegistered',
          category: 'account',
          label: `User ${user?.id} registered.`,
          userId: user?.id,
          userAge: (user as FanVestorDetail).birthdate
            ? dayjs().diff(dayjs((user as FanVestorDetail)?.birthdate), 'year')
            : 'No birthdate specified',
          userCountry: (user as FanVestorDetail)?.country || 'No country specified',
          userLanguage: i18n.global.t(`common.settings.${user?.language}`, {
            locale: 'en',
          }),
        })

        return to.query.redirect_path
          ? { path: to.query.redirect_path as string }
          : { name: FANVESTOR, params: { sport: to.params.sport } }
      })
      .catch(() => {
        return { name: LOGIN }
      })
  }

  // If auth is not required, just continue
  if (!to.meta.auth) {
    return true
  }

  // If a user is logged in and tries to access the login page, don't redirect
  if (user && to.name === LOGIN) {
    return { path: from.path }
  }

  // If a user tries to access a page
  // that requires a role that they don't have access to, don't redirect
  if (
    (user?.role === UserRole.ClubUser && !!to.meta.authFanvestor) ||
    (user?.role === UserRole.FanVestor && !!to.meta.authClub) ||
    (user?.role === UserRole.ClubUser &&
      +to.params.clubId !== user.club_id &&
      to.name !== CLUB_PROFILE)
  ) {
    return { path: from.path }
  }

  //If user is fanvestor but did not provide personal info or his age is less than 18
  // don't allow redirect to fund funding checkout stage
  if (
    to.name === FUNDING_CHECKOUT &&
    user?.role === UserRole.FanVestor &&
    !user.is_eligible_to_fund
  ) {
    return { path: from.path }
  }

  // If a user is not logged in, redirect to the login page
  if (!user) {
    return loginRoute
  }

  return true
}
