import 'dayjs/locale/en'
import 'dayjs/locale/de'
import 'dayjs/locale/fr'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isoWeek from 'dayjs/plugin/isoWeek'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import localeData from 'dayjs/plugin/localeData'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import i18n from './i18n'

dayjs.extend(customParseFormat)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(isoWeek)
dayjs.extend(localeData)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.locale(i18n.global.locale.value)

dayjs.tz.setDefault('UTC')

export default dayjs
