import { Capacitor } from '@capacitor/core'
import axios from 'axios'

import { ApiClient } from '@/generated/api/ApiClient'
import router from '@/router'

import i18n from './i18n'

let client: ApiClient | null = null
export const useApiClient = () => {
  if (!client) {
    client = new ApiClient({
      BASE: Capacitor.isNativePlatform() ? import.meta.env.VITE_BASE_URL : '',
      HEADERS: async (): Promise<Record<string, string>> => {
        return { 'Accept-Language': i18n.global.locale.value }
      },
    })
  }
  return client
}

axios.interceptors.response.use(
  (response) => response, // Return the response if successful
  async (error) => {
    if (error.response?.status >= 500) {
      await router.push('/500')
    }
    return Promise.reject(error)
  }
)
