/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Country } from '../models/Country';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CountriesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns Country
     * @throws ApiError
     */
    public countriesList(): CancelablePromise<Array<Country>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/countries/',
        });
    }
}
