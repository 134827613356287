<template>
  <div class="bg-wrapper">
    <div class="background-image" :class="{ hockey: route.params.sport === SPORTS.HOCKEY }"></div>
  </div>
  <div
    class="h-full flex flex-col relative w-full md:ml-32 md:max-w-[421px]"
    :class="{ 'safe-top': Capacitor.isNativePlatform() }"
  >
    <header
      class="py-[18px] px-5 flex items-center justify-between border-white-10 border-b-[1px] md:border-none"
      :class="{
        'mb-10': route.query.referralBy || route.query.sharedByClub || route.query.sharedByFan,
      }"
    >
      <template
        v-if="!route.query.referralBy && !route.query.sharedByClub && !route.query.sharedByFan"
      >
        <SportSelector :selected-sport="route.params.sport" />
        <LanguageDropdown class="self-start" />
      </template>
      <div v-else class="flex flex-col gap-5 grow">
        <div class="flex justify-between">
          <Avatar club-logo :url="club?.logo" size="xl"></Avatar>
          <LanguageDropdown class="self-start" />
        </div>
        <div class="flex flex-col grow gap-2">
          <p class="text-2xl italic font-extrabold text-white">{{ club?.name }}</p>
          <p class="font-bold caps text-sm text-accent-500">
            {{ $t('fanvestor.shared-funding-header.powered-by-crowdtransfer') }}
          </p>
        </div>
      </div>
    </header>
    <div class="flex flex-col flex-grow px-5 mt-10 md:max-w-[421px]">
      <div class="flex-grow flex transition-all">
        <div class="flex-grow">
          <slot />
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { Capacitor } from '@capacitor/core'
import { onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'

import Avatar from '@/components/common/avatar/Avatar.vue'
import LanguageDropdown from '@/components/common/language-dropdown/LanguageDropdown.vue'
import Footer from '@/components/page/Footer.vue'
import { ClubReadModel } from '@/generated/api'
import SportSelector from '@/modules/fanvestor/components/SportSelector.vue'
import { useFanvestorsClubsStore } from '@/modules/fanvestor/store/clubs'
import { SPORTS } from '@/utils/variables'

const fanvestorsClubsStore = useFanvestorsClubsStore()
const route = useRoute()
const club = ref<ClubReadModel>()

onBeforeMount(async () => {
  const clubID = route.query.clubId ?? route.query.referralBy ?? route.query.sharedByClub
  if (clubID) {
    club.value = await fanvestorsClubsStore.getClub(+clubID)
  }
})
</script>
<style scoped lang="postcss">
.bg-wrapper {
  @apply h-screen fixed w-screen overflow-hidden;
}
.background-image {
  background-image: url('@/assets/images/crowd.png');
  @apply fixed -z-10 -right-[calc(100vh*0.2)] max-w-[100vh] bg-cover h-[calc(100vh*1.5)] -top-[25%] w-full;

  &.hockey {
    background-image: url('@/assets/images/crowd-hockey.png');
  }
}
</style>
