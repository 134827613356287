<template>
  <div
    class="h-full flex flex-col justify-between relative w-full max-w-screen-app-max px-5 m-auto"
    :class="{ 'safe-top': Capacitor.isNativePlatform() }"
  >
    <header class="h-[72px] flex items-center flex-shrink-0 justify-between">
      <Logo class="w-40" :hockey="$route.params.sport === SPORTS.HOCKEY" />
      <div class="flex gap-2">
        <ProfileMenu :user="authStore.user" />
      </div>
    </header>
    <div class="flex-grow -mx-5">
      <slot />
    </div>
    <Footer />
    <BackgroundHighlight />
  </div>
</template>
<script lang="ts" setup>
import { Capacitor } from '@capacitor/core'

import BackgroundHighlight from '@/components/page/BackgroundHighlight.vue'
import Footer from '@/components/page/Footer.vue'
import Logo from '@/components/page/Logo.vue'
import ProfileMenu from '@/components/page/ProfileMenu.vue'
import { useAuthStore } from '@/modules/auth/store'
import { SPORTS } from '@/utils/variables'
const authStore = useAuthStore()
</script>
